export const menuItems = [
  {
    "id": 1,
    "name": "Banku 2 balls with okro stew (tilapia, cow meat, crab)",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.41_f14f7ef9.jpg?alt=media&token=d75ae1d0-a2e7-410b-8179-b89aef8dbec6",
    "price": 70
  },
  {
    "id": 2,
    "name": "Banku 2 balls with okro stew (tilapia, cow meat, crab)",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.41_f14f7ef9.jpg?alt=media&token=d75ae1d0-a2e7-410b-8179-b89aef8dbec7",
    "price": 60
  },
  {
    "id": 3,
    "name": "Fufu with tilapia and goat meat",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/648ab74e626bf61c4d339bea7ee4135a.jpg?alt=media&token=c237bc5b-ae74-44dd-9069-38957f13ebf4",
    "price": 60
  },
  {
    "id": 4,
    "name": "Fufu with tilapia and cow meat",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/648ab74e626bf61c4d339bea7ee4135a.jpg?alt=media&token=c237bc5b-ae74-44dd-9069-38957f13ebf4",
    "price": 45
  },
  {
    "id": 5,
    "name": "Fufu with fresh or dry salmon and goat meat",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/125201851705_tilapia1.jpg?alt=media&token=30cfb55a-9024-40b8-8308-3fe8e66b0293",
    "price": 55
  },
  {
    "id": 6,
    "name": "Fufu with fresh or dry salmon and cow meat",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/125201851705_tilapia1.jpg?alt=media&token=30cfb55a-9024-40b8-8308-3fe8e66b0293",
    "price": 40
  },
  {
    "id": 7,
    "name": "Plain rice with 1 fried chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.44_d1fbcf9e.jpg?alt=media&token=a03abe59-f312-4247-9fb8-4b32293f381b",
    "price": 35
  },
  {
    "id": 8,
    "name": "Plain rice with 2 fried chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.44_d1fbcf9e.jpg?alt=media&token=a03abe59-f312-4247-9fb8-4b32293f381b",
    "price": 60
  },
  {
    "id": 9,
    "name": "Fried yam with fried chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.43_e06b00e0.jpg?alt=media&token=26a72360-0444-4ac5-b161-3e4bd4784377",
    "price": 30
  },
  {
    "id": 10,
    "name": "Fried yam with grilled chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.43_e06b00e0.jpg?alt=media&token=26a72360-0444-4ac5-b161-3e4bd4784377",
    "price": 35
  },
  {
    "id": 11,
    "name": "Fried rice with 1 fried chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.41_3678d530.jpg?alt=media&token=b10a295a-420f-4869-89f5-6862c329d5d4",
    "price": 35
  },
  {
    "id": 12,
    "name": "Fried rice with 2 fried chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.41_3678d530.jpg?alt=media&token=b10a295a-420f-4869-89f5-6862c329d5d4",
    "price": 60
  },
  {
    "id": 13,
    "name": "Jollof rice with 1 grilled chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.42_5da71cf4.jpg?alt=media&token=7cbcb3ba-5335-472e-8499-cd89c817bf3b",
    "price": 40
  },
  {
    "id": 14,
    "name": "Jollof rice with 2 grilled chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.42_5da71cf4.jpg?alt=media&token=7cbcb3ba-5335-472e-8499-cd89c817bf3b",
    "price": 70
  },
  {
    "id": 15,
    "name": "Special salad with egg, bake beans and fried chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.43_5561f110.jpg?alt=media&token=ee989f9e-70de-4b9a-94dd-648b3c2a8447",
    "price": 40
  },
  {
    "id": 16,
    "name": "Special salad with egg, bake beans and grilled chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.43_5561f110.jpg?alt=media&token=ee989f9e-70de-4b9a-94dd-648b3c2a8447",
    "price": 45
  },
  {
    "id": 17,
    "name": "Grilled tilapia and fried yam",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.43_a5820902.jpg?alt=media&token=c48edfe0-b395-43ef-be7a-f28a44d21991",
    "price": 65
  },
  {
    "id": 18,
    "name": "Grilled tilapia and banku 2 balls",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-04%20at%2008.40.44_45dc01da.jpg?alt=media&token=232042b0-f819-4ad6-a71c-2d5658ad5fc9",
    "price": 40
  },
  {
    "id": 19,
    "name": "Special sobolo",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-05%20at%2018.02.51_4193b889.jpg?alt=media&token=8d81c33c-c4f5-4bec-bbfe-9a38f102a2fa",
    "price": 12
  },
  {
    "id": 20,
    "name": "Meat pie",
    "category": "Menu",
    "img": "",
    "price": 12
  },
  {
    "id": 21,
    "name": "2 meat pie with pineapple juice",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-05%20at%2018.02.51_023647a1.jpg?alt=media&token=9ce63502-8452-49e5-abde-1bcece9fcbe8",
    "price": 40
  },
  {
    "id": 22,
    "name": "2 meat pie with sobolo",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-05%20at%2018.02.52_d6b1634e.jpg?alt=media&token=922b1c93-c132-4a96-b84c-03d428a0878d",
    "price": 36
  },
  {
    "id": 23,
    "name": "Special spring rolls",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-05%20at%2018.02.52_141f4c13.jpg?alt=media&token=3816787b-0129-42b5-aa6c-6fcda1e684d4",
    "price": 20
  },
  {
    "id": 24,
    "name": "Special cookies 5 per 1",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-12%20at%2009.47.35_210a1c70.jpg?alt=media&token=88c6ff91-ccb3-4ea5-b9aa-38a387015d9e",
    "price": 5
  },
  {
    "id": 25,
    "name": "Special cake 7 per 1",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-12%20at%2009.47.37_d9251242.jpg?alt=media&token=de1df81e-b20b-4815-a49c-965dc60c3d44",
    "price": 7
  },
  {
    "id": 26,
    "name": "Special bake chips 2.5 per 1",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-12%20at%2009.47.37_1db08146.jpg?alt=media&token=a3e73c04-72f8-4639-baf1-d3f4880c3c3e",
    "price": 2.5
  },
  {
    "id": 27,
    "name": "Special noddles sausage and fried egg",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/the-next-ebc73.appspot.com/o/WhatsApp%20Image%202024-06-12%20at%2009.47.34_3df393ce.jpg?alt=media&token=4c8690fb-5432-4169-b2e2-5e59a5bacc2c",
    "price": 40
  }
]