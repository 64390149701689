import React from "react";
import Banner from "./components/Banner";
import Menu from "./components/Menu";
import HomeNav from "./components/HomeNav";
import Footer from "./Footer";
import ScrollTop from "./components/ScrollTop";

function Home({ active }) {
  return (
    <>
      <HomeNav />
      <Banner />
      {/* <div className="menu-background"></div> */}
      <Menu active={active} />
      <Footer />
      <ScrollTop/>
    </>
  );
}

export default Home;
  