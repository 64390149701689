import {
  Button,
} from "@material-tailwind/react";
import React from 'react'
import './Main.css'


const Banner = () => {
  return (
    <div className='banner-image'>
      <div className='banner-text'>
        <div>
          <h2>Mouth-watering local dishes,</h2>
          <h2>and continental favorites!</h2>
        </div>

        <div className="button-container">
          <Button
            className="rounded-none p-0 mt-4 bg-orange-600 shadow-none flex items-center justify-center lg:inline-block"
            style={{ borderColor: '#FFC107', padding: 0, marginRight: '10px'}}
          >
            <a
              href="#sectionmenu"
              className="flex items-center justify-center w-full h-full text-white"
              style={{
                padding: '18px 30px',
                fontSize: '1.5rem',
                fontWeight: '500',
                textDecoration: 'none',
                textTransform: 'capitalize',
              }}
            >
              View Menu
            </a>
          </Button>
          <Button
            className="rounded-none p-0 mt-4 bg-transparent shadow-none flex items-center justify-center lg:inline-block border-white"
            style={{ borderColor: '#FFC107', borderWidth: '2px', padding: 0 }}
          >
            <a
              href="#sectioncontact"
              className="flex items-center justify-center w-full h-full text-white hover:text-amber-400"
              style={{
                padding: '16px 30px',
                fontSize: '1.5rem',
                fontWeight: '500',
                textDecoration: 'none',
                textTransform: 'capitalize',
              }}
            >
              Contact Us
            </a>
          </Button>
        </div>

      </div>
    </div>

  )
}

export default Banner